import logo from './assets/logo.webp';
import pastor from './assets/pastor.webp';
import phone from './assets/phone.webp';
import kakao from './assets/kakao.webp';
import { Footer } from './Footer.js';
import { Header } from './Header.js';
import { Section } from './Section.js';
import './App.css';
import { useEffect, useState, useRef } from 'react';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
    { threshold: 1.0 },
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect(); };
  }, []);

  return isIntersecting;
}

function App() {
  const [moreBarVisibility, setMoreBarVisibility] = useState(true);
  const playerRef = useRef();
  const isPlayerVisible = useOnScreen(playerRef);
  window.onscroll = function () {
    setMoreBarVisibility(window.pageYOffset <= window.innerHeight * 0.1);
  };

  return (
    <div className={`App ${isPlayerVisible ? 'Color-alt' : ''}`} >
      <Header
        logo={logo}
        title="샌프란시스코성결교회"
      />
      <div style={{ height: '48px' }}></div>
      <Section>
        <div className="Title-container">
          <div className="Main-banner">
            <h1 className="Title">
              <span className="Text-bold">함께 웃고 함께 우는 공동체 </span> 샌프란시스코 성결교회입니다.
            </h1>
          </div>
          <div className={`More-bar ${moreBarVisibility ? 'More-bar-fade-in' : ''}`}>
            <div className="More-bar-text">밑으로 내리세요 ↓</div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="Content-container" style={{ paddingTop: '160px' }}>
          <p className="Header">
            인생의 방황은 <span className="Text-bold">예수님을 만나면</span> 끝이 납니다.
          </p>
        </div>
      </Section>
      <Section>
        <div className="Content-container">
          <p className="Header">
            예수님을 함께 섬기며, 함께 웃고, 함께 우는 공동체인 샌프란시스코 성결교회로  <span className="Text-bold-accent">여러분을 초대합니다</span>.
          </p>
        </div>
      </Section>
      <Section>
        <div className="Content-container" style={{
          display: 'flex', flexDirection: 'column',
          alignItems: 'center'
        }}>
          <div className="Frame-container-sticky">
            <div className="Player-container" ref={playerRef}>
              <iframe
                className="Frame"
                src="https://www.youtube.com/embed?max-results=1&controls=1&showinfo=0&rel=0&listType=user_uploads&list=kecsf"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="Content-container" style={{ marginTop: '144px' }}>
          <div className="Description-container">
            <div className="Description-title">예배 안내</div>
            <div className="Description-line"><span className="Text-bold">주일 예배</span><span>11시 • 2층</span></div>
            <div className="Description-line"><span className="Text-bold">중고등부 예배</span><span>11시 • 1층</span></div>
            <div className="Description-line"><span className="Text-bold">어린이부 예배</span><span>11시 • 3층</span></div>
            <div className="Description-line"><span className="Text-bold">청년 모임</span><span>2시 • 2층</span></div>
            <div className="Description-line"></div>
            <div className="Frame-container">
              <iframe
                className="Frame"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCUudGu8Q0Mzg4gW75IZv8V6huw_lgR82E
            &q=샌프란시스코+성결교회+Korean+Evangelical+Church,542+San+Juan+Ave,San+Francisco+CA"
                frameBorder="0"
                title="video"
              />
            </div>
            <div className="Description-text-xsmall" style={{ marginTop: '48px' }}>
              샌프란시스코 시티 칼리지 부근, 발보아 파크 BART 역에서 가까워 차가 없어도 오시기 어렵지 않고,
              I-280과 인접하여 교통이 매우 편리합니다. 교회 주소는 <a className="Text-bold" target="_blank" rel="noreferrer"
                href="https://www.google.com/maps/dir//%EC%83%8C%ED%94%84%EB%9E%80%EC%8B%9C%EC%8A%A4%EC%BD%94+%EC%84%B1%EA%B2%B0%EA%B5%90%ED%9A%8C+Korean+Evangelical+Church+of+San+Francisco,+542+San+Juan+Ave,+San+Francisco,+CA+94112/@37.7269794,-122.4433169,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808f7e874d090157:0x38357326b7f51ee9!2m2!1d-122.441078!2d37.726951">
                542 San Juan Ave, San Francisco, CA 94112
              </a> 입니다.</div>
            <div className="Description-text-xsmall" style={{ marginTop: '0px' }}>
              길 안내 및 신앙상담 문의가 있으시면 연락 주세요. 자세히 안내 해드리겠습니다.
              <div className="Link-container">
                <a className="Link-phone" href="tel:716-517-0466"><img src={phone} width={25} height={25} alt="Phone" />(716) 517-0466</a>
                <a className="Link-kakao" href="https://open.kakao.com/o/siYrUoue" target="_blank" rel="noreferrer"><img src={kakao} width={25} height={25} alt="Kakaotalk"/>1:1 오픈채팅</a>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className="Content-container" style={{ margin: '72px 0px', minHeight: '0px' }}>
          <div className="Description-container">
            <div className="Description-title">목사님 소개</div>
            <div className="Description-half-container">
              <img className="Section-image" src={pastor} alt="Pastor"></img>
              <div>
                <div className="Description-text-small">
                  <span className="Text-bold">황유선 목사</span>는 서울신학대학, 고든콘웰 신학대학원, 이스라엘 예루살렘 대학교, 스웨덴 예수전도단 DTS, 보스톤대학 대학원, 컨콜디아 신학대학원 등에서 <span className="Text-bold">신학과 경건 훈련</span>을 받았습니다.</div>
                <div className="Description-text-small"><span className="Text-bold">목회자 가정</span>에서 자라서 어려서부터 목회자에 대한 꿈과 비젼을 가지고 성장하였습니다. 세계 선교에 대한 비전과 민족복음화에 대한 헌신을 품고, OM 선교회와 죠이선교회, 예수전도단 등에서 <span className="Text-bold">제자훈련과 선교 훈련</span>을 받았습니다.</div>
                <div className="Description-text-small">한국과 미국의 여러 한인교회에서 어린이부, 청소년부, 청년, 유학생부, 장년 사역까지 다양한 분야의 사역을 통해 지역교회를 통한 <span className="Text-bold">하나님 나라의 부흥</span>을 경험하였습니다.</div>
                <div className="Description-text-small">부족하지만 끊임없이 기도하며 섬기는 목회자가 되기를 간절히 바라며 겸손히 나아가겠습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Footer
        info={[
          "© Korean Evangelical Church of San Francisco",
          "542 San Juan Ave, San Francisco, CA 94112",
          "kecsf@yahoo.com | (415) 334-2415",
        ]} />
    </div>
  );
}

export default App;
