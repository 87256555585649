import './Header.css';

export const Header = (props) => {
    return (<header className="App-header">
        <img className="Logo-img" src={props.logo} alt="logo" />
        <span className="Logo-text">
            {props.title}
        </span>
    </header>);
};
